<template>
  <div class="container">
    <div v-if="ashow == true" class="shlog">
      <div class="shin">
        <img class="shlog1" src="@/assets/images/share_action_icon@2x.png" />
        <img class="shlog2" src="@/assets/images/pic_action_icon@2x.png" />
        <div class="shlog4">点击右上角“...”，分享到</div>
        <div class="shlog3">
          <img class="sslog" src="@/assets/images/wx_action_icon@2x.png" />
          <span>微信好友或</span>
          <img class="sslog1" src="@/assets/images/pyq_action_icon@2x.png" />
          <span>微信朋友圈~</span>
          <div class="known" @click="ashow = false">知道了</div>
        </div>
      </div>
    </div>
    <div class="title">{{ list.Title }}</div>
    <div class="item_label">
      <span v-for="(item, index) in slName" :key="index">{{ item }}</span>
    </div>
    <div class="timetab">
      <div class="time">
        {{ list.CreateTime | dateFilter(list.CreateTime) }}
      </div>
      <!-- <div class="fontSize">
        字号
        <img src="@/assets/images/switchSmall@2x.png" />
      </div> -->
    </div>
    <div>
      <div class="sicon">
        <img
          style="vertical-align: middle; margin-right: 5px"
          src="@/assets/images/browse_comm_icon@2x.png"
        />
        <span style="vertical-align: middle">{{ list.WatchCount }}</span>
      </div>
      <div class="sicon">
        <img
          style="width: 1.3em; vertical-align: middle; margin-right: 5px"
          src="@/assets/images/like_comm_icon@2x.png"
        />
        <span style="vertical-align: middle">{{ list.LikeCount }}</span>
      </div>
    </div>
    <div class="content1" v-show="list.Type == 1">
      <!-- <iframe
        class="frame"
        :src="list.Video"
        scrolling="auto"
        frameborder="0"
      ></iframe> -->
      <!-- <video
        id="player-container-id"
        width="430"
        height="270"
        preload="auto"
        playsinline
        webkit-playsinline
      ></video> -->
      <video
        id="player-container-id"
        style="width: 100%; height: 320px"
        preload="auto"
        playsinline
        webkit-playsinline
      ></video>
      <div class="detail">
        <!-- {{ list.VContent }} -->
        <div class="VContent" v-html="list.VContent"></div>
        <div class="banner" v-html="list.ImageText">
          <!-- <img class="Thumbnail" style="width: 100%" :src="item.Url" />
          <p v-html="item.Desc"></p> -->
          <!-- {{ item.Desc }} -->
        </div>
      </div>
    </div>

    <div class="content2" v-if="list.Type == 2">
      <div class="detail">
        <!-- {{ list.VContent }} -->
        <div class="banner" v-html="list.ImageText">
          <!-- <van-swipe class="my-swipe" @change="onChange">
            <van-swipe-item
              ><img style="width: 100%" :src="item.Url"
            /></van-swipe-item>
          </van-swipe> -->
          <!-- <div class="custom-indicator" slot="indicator">
              {{ current + 1 }}/{{ Object.keys(list.fileList).length }}
            </div> -->
          <!-- <div class="bannertop">新院选址</div> -->
          <!-- <div class="Desc" style="margin: 15px 0" v-html="item.Desc"></div> -->
        </div>
      </div>
    </div>

    <div class="content3" v-if="list.Type == 3">
      <div class="detailzb">
        <van-button
          type="primary"
          class="want"
          @click="addyy"
          v-if="yyyes == false && list.LiveState == '1'"
          >我要预约</van-button
        >
        <van-button
          type="primary"
          class="want"
          @click="delyy"
          v-if="yyyes == true && list.LiveState == '1'"
          v-show="yyshow"
          >取消预约</van-button
        >
        <van-button
          type="primary"
          class="want"
          @click="enteryy"
          v-if="list.LiveState == '2'"
          >进入直播</van-button
        >
        <div class="banner" v-html="list.ImageText"></div>
        <!-- <img
          v-for="(item, index) in list.fileList"
          :key="index"
          class="Thumbnail"
          style="width: 100%; margin: 0"
          :src="item.Url"
        /> -->
      </div>
    </div>

    <div class="article" v-if="list.Type !== 3">
      <div class="articletitle">参考文献</div>
      <div
        class="articleinfo"
        style="white-space: pre-wrap"
        v-html="list.Reference"
      >
        <!-- {{ list.Reference }} -->
      </div>
    </div>

    <div
      class="filedownload"
      v-if="list.Type == 2 && list.FilesName != '' && list.FilesName != null"
    >
      <img src="@/assets/images/pdf_ny_tab@2x.png" />
      <div class="fileTitle">
        <a :href="list.FilesUrl" :download="list.FilesName"></a>
        {{ list.FilesName }}
      </div>
      <div class="filebottom">
        <!-- @click="filedownload(list.FilesUrl)" -->
        点击下载>>
      </div>
    </div>

    <div class="dzmain" v-if="list.Type !== 3">
      <div class="dztitle">欢迎您对本文打星评价</div>
      <div class="raterow">
        <div class="ratename">专业度</div>
        <van-rate
          v-model="rate1"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>
      <div class="raterow">
        <div class="ratename">实用性</div>
        <van-rate
          v-model="rate2"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>
      <div class="raterow">
        <div class="ratename">推荐度</div>
        <van-rate
          v-model="rate3"
          :size="25"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
          gutter="13px"
        />
      </div>

      <van-button type="primary" class="rateupload" v-if="ratebtnshow == 0">
        <!-- @click="addArticleStars()" -->
        提交</van-button
      >
      <van-button
        type="primary"
        class="rateupload"
        @click="editArticleStars()"
        v-else
        >修改评分</van-button
      >
    </div>

    <div class="rowbtn">
      <van-button plain type="primary" class="plbtn">
        <!-- @click="showPopup" -->
        我要点评</van-button
      >
    </div>

    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '28%' }"
    >
      <van-cell-group class="plinput">
        <van-field v-model="plform.Content" placeholder="请输入评论内容" />
      </van-cell-group>
      <van-button type="primary" class="rateupload">
        <!-- @click="plupload" -->
        提交</van-button
      >
    </van-popup>

    <div class="plmain">
      <div
        class="plrow"
        v-if="Object.keys(CommentList).length == 0"
        style="text-align: center"
      >
        暂无新的评论
      </div>
      <div
        class="plrow"
        v-else
        v-for="(item, index) in CommentList"
        :key="index"
      >
        <div class="pltop">
          <div class="tx">
            <img class="plicon" style="" :src="item.Headimgurl" />
          </div>
          <div class="plname">{{ item.CusRealName }}</div>
          <div class="pltime">{{ item.Time | dateFilter(item.Time) }}</div>
        </div>
        <div class="pltext">
          {{ item.Content }}
        </div>

        <div class="pldz">
          <img
            class="plicon"
            style=""
            v-if="item.LikeState == false"
            src="@/assets/images/likes_ny_icon_nor@2x.png"
          />
          <!-- @click="pldz(item.Id)" -->
          <img
            class="plicon"
            style=""
            v-else
            src="@/assets/images/likes_ny_icon_alt@2x.png"
          />
          <!-- @click="plqxdz(item.Id)" -->
          <span>{{ item.LikeCount }}点赞</span>
        </div>
      </div>
    </div>

    <div class="aboutmain">
      <div class="abouttop">相关内容</div>
      <div class="aboutrow" v-if="showRecommendList" style="text-align: center">
        敬请期待
      </div>
      <!-- <div
        class="aboutrow"
        v-if="Object.keys(list.RecommendList).length == 0"
        style="text-align: center"
      >
        敬请期待
      </div> -->
      <div
        class="aboutrow"
        v-for="(item, index) in list.RecommendList"
        :key="index"
      >
        <!-- @click="jump(item.Id, item.Type)" -->
        <div class="aboutimg">
          <img class="imgmain" :src="item.Thumbnail" />
          <img class="playicon" src="@/assets/images/play_comm_icon@2x.png" />
        </div>
        <div class="aboutmaininfo">
          <div class="abouttitle">
            {{ item.Title }}
          </div>
          <div class="aboutname">讲者：{{ item.Speaker }}</div>
          <div class="abouthos">医院：{{ item.SHospital }}</div>
          <div>
            <div class="sicon ssicon" style="margin-left: 0">
              <img
                style="width: 1.3em"
                src="@/assets/images/browse_comm_icon@2x.png"
              />
              {{ item.WatchCount }}
            </div>
            <div class="sicon ssicon">
              <img
                style="width: 1.2em"
                src="@/assets/images/like_comm_icon@2x.png"
              />
              {{ item.LikeCount }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="toTop" @click="toTop">
      <a href="javascript:void(0)">
        <img src="@/assets/images/top_comm_icon@2x.png" />
      </a>
    </div>

    <div class="bottombar">
      <div class="bottomrow" v-if="dzyes == false">
        <!-- @click="adddz" -->
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/good_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">点赞</div>
      </div>
      <div class="bottomrow" v-else v-show="dzshow">
        <!-- @click="deldz" -->
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/good_ny_icon_alt@2x.png"
          />
        </div>
        <div class="bottomtext">点赞</div>
      </div>
      <div class="bottomrow" v-if="scyes == false">
        <!-- @click="addsc" -->
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/collect_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">收藏</div>
      </div>
      <div class="bottomrow" v-else v-show="scshow">
        <!-- @click="delsc" -->
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/collect_ny_icon_alt@2x.png"
          />
        </div>
        <div class="bottomtext">收藏</div>
      </div>
      <div class="bottomrow">
        <!-- @click="sharefunc" -->
        <div>
          <img
            class="bottomimg"
            src="@/assets/images/share_ny_icon_nor@2x.png"
          />
        </div>
        <div class="bottomtext">转发</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import { Dialog } from "vant";
import wx from "weixin-js-sdk";
import utils from "../../common/utils/utils";
export default {
  name: "ContentPage",
  data() {
    return {
      appId: "",
      timestamp: "",
      nonceStr: "",
      signature: "",
      list: [],
      Comment: {},
      CommentList: [],
      slName: [],
      watched: {},
      dzt: {},
      ztmain: [],
      id: 0,
      checked: false,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      current: 0,
      show: false,
      customerid: "",
      plform: {
        ArticleId: 0,
        CustomerId: "",
        Type: 4,
        Content: "",
      },
      CommentLikelist: {
        CustomerId: "",
        CommentId: "",
      },
      dzyes: false,
      scyes: false,
      yyyes: false,
      dzshow: false,
      scshow: false,
      yyshow: false,
      getstar: {},
      stars: [],
      ratebtnshow: 0,
      shareurl: {},
      weixinvalue: {
        appId: "",
        timestamp: "",
        noncStr: "",
        signature: "",
      },
      utype: "",
      backlog: {
        ShareUid: 0,
        SeeUid: 0,
        ShareUri: "",
        Code: "",
        ShareId: 0,
        seeType: "",
      },
      sharefrom: 0,
      sharecode: "",
      ssurl: "",
      timepoint: {
        ArticleId: "",
        Type: "",
        Date: "",
      },
      timer: null,
      pathname: null,
      //fxUrl: "",
      uprate1: 0,
      uprate2: 0,
      uprate3: 0,
      showRecommendList: false,
      player: null,
      ashow: false,
      kpng: require("../../assets/images/k.png"),
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  setup() {
    const checked = ref(true);
    return { checked };
  },
  rate1() {
    const rate1 = ref(0);
    return { rate1 };
  },
  rate2() {
    const rate2 = ref(0);
    return { rate2 };
  },
  rate3() {
    const rate3 = ref(0);
    return { rate3 };
  },
  created() {
    this.getInfo();
    // this.getUrl();
    // this.getComment();
    // this.enter();
    // this.dzzt();
    // this.getArticleStars();
    // this.initShare();
    // this.fromLog();
    // alert("https://wcwechatqa.jjmc.cn/"+this.kpng);
  },
  mounted() {
    // this.initVideo();
  },
  beforeDestroy() {
    this.player.dispose();
  },
  methods: {
    //记录分享人行为数据
    fromLog() {
      //从url参数中获取分享人id和code
      this.utype = this.$route.query.seetype;
      if (this.utype == 2) {
        //sharefrom  sharecode
        this.sharefrom = this.$route.query.shareuid;
        this.sharecode = this.$route.query.code;
        this.sharelog();
      }
    },
    // 初始化直播
    initVideo() {
      let Options = {};
      if(this.list.fileid) {
        Options = {
          playbackRates: [0.5, 1, 1.5, 2],
          fileID: this.list.fileid,
          appID: this.list.appid,
          psign: this.list.psign
        };
        this.player = this.TCPlayer("player-container-id", Options).player;
      } else {
        Options = {
          playbackRates: [0.5, 1, 1.5, 2]
        };
        this.player = this.TCPlayer("player-container-id", Options).player;
        this.player.src(this.list.Video);
      }
    },
    getInfo() {
      this.id =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      let that = this;
      if (this.id > 0) {
        that.$axios
          .post("/Api/Api/Web/Article/GetAtlDetile?id=" + this.id)
          .then((res) => {
            let data = res.Data;
            if (res.Data.RecommendList.length == 0) {
              this.showRecommendList = true;
            }
            that.list = data;
            this.initVideo();
            // this.timer = setInterval(() => {
            //   this.timeline();
            // }, 10000);
            if (res.Data.SLName) {
              this.slName = res.Data.SLName.split(",");
              this.slName.splice(this.slName.indexOf(""), 1);
            }
            that.loading = false;
          });
      }
    },

    initShare() {
      let that = this;
      const entryLink = utils.getEntryLink();
      that.$axios
        .get("/Api/Api/web/WechatHelper/GetWechat?backUrl=" + entryLink)
        .then((res) => {
          wx.config({
            debug: false,
            appId: res.Data.AppId,
            timestamp: res.Data.TimesTamp,
            nonceStr: res.Data.NonceStr,
            signature: res.Data.She1Str,
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
          });
          wx.checkJsApi({
            jsApiList: [
              // 所有要调用的 API 都要加到这个列表中
              "updateAppMessageShareData", // 分享到朋友圈接口
              "updateTimelineShareData", //  分享到朋友接口
            ],
            success: async function (res) {
              var url = await that.getShareUrl();
              wx.ready(() => {
                wx.updateAppMessageShareData({
                  title: that.list.Title,
                  link: url,
                  desc: that.list.Title,
                  imgUrl: "https://wcwechatqa.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
                wx.updateTimelineShareData({
                  title: that.list.Title,
                  link: url,
                  imgUrl: "https://wcwechatqa.jjmc.cn/" + that.kpng,
                  success: function (res) {},
                });
              });
            },
          });
        });
    },

    sharefunc() {
      let that = this;
      //
      //这里提示用户去右上角分享
      //
      that.ashow = true;
      //记录分享日志
      that.sharelog();
    },

    getShareUrl() {
      let that = this;
      this.shareurl.customerid = this.userInfo.Id;
      this.shareurl.url = encodeURIComponent(
        window.location.href.split("#")[0]
      );
      return new Promise((resolve) => {
        that.$axios
          .get(
            "/Api/Api/web/ShareLog/GetShareUrl?url=" +
              that.shareurl.url +
              "&customerid=" +
              this.shareurl.customerid
          )
          .then((res) => {
            resolve(res.Data.Url);
          });
      });
    },

    sharelog() {
      let that = this;
      that.backlog = {
        ShareUid: this.sharefrom,
        SeeUid: this.userInfo.Id,
        ShareUri: window.location.href,
        Code: this.sharecode,
        ShareId: this.list.Id,
        seeType: this.utype,
      };
      that.$axios
        .post("/Api/Api/Web/ShareLog/AddShare", this.backlog)
        .then((res) => {});
    },

    getComment() {
      this.Comment.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.Comment.CustomerId = this.userInfo.Id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/GetComment", this.Comment)
        .then((res) => {
          that.CommentList = res.Data;
          that.loading = false;
        });
    },
    onChange(index) {
      this.current = index;
    },
    showPopup() {
      this.show = true;
    },
    plupload() {
      this.plform.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.plform.CustomerId = this.userInfo.Id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/AddComment", this.plform)
        .then((res) => {
          if (res.RetCode == "10000") {
            Dialog.alert({
              message: "提交成功，审核通过后可以看到该评论",
            }).then(() => {
              this.show = false;
            });
          }
        });
    },
    pldz(id) {
      this.CommentLikelist.CustomerId = this.userInfo.Id;
      this.CommentLikelist.CommentId = id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/AddCommentLike", this.CommentLikelist)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
          this.getComment();
        });
    },
    plqxdz(id) {
      this.CommentLikelist.CustomerId = this.userInfo.Id;
      this.CommentLikelist.CommentId = id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/delCommentLike", this.CommentLikelist)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
          this.getComment();
        });
    },
    dzzt() {
      this.dzt.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.dzt.CustomerId = this.userInfo.Id;
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/HandleState", this.dzt)
        .then((res) => {
          this.ztmain = res.Data;
          for (var i = 0; i < this.ztmain.length; i++) {
            if (this.ztmain[i].type == "3" && this.ztmain[i].State == true) {
              this.dzyes = true;
              this.dzshow = true;
            }
            if (this.ztmain[i].type == "2" && this.ztmain[i].State == true) {
              this.scyes = true;
              this.scshow = true;
            }
            if (this.ztmain[i].type == "5" && this.ztmain[i].State == true) {
              this.yyyes = true;
              this.yyshow = true;
            }
          }
        });
    },

    // timeline() {
    //   let that = this;
    //   let d = new Date().getTime();
    //   if (this.list.type == "1") {
    //     this.timepoint.Type = "2";
    //   } else {
    //     this.timepoint.Type = "1";
    //   }
    //   this.timepoint.ArticleId = this.list.Id;
    //   this.timepoint.Date = d;
    //   that.$axios
    //     .post("/Api/Api/Web/BuriedPoint/AddDur", this.timepoint)
    //     .then((res) => {});
    // },

    enter() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "1";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          // this.list.CommentList.LikeState = true;
        });
    },
    adddz() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "3";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.dzzt();
          this.dzshow = true;
          // this.list.CommentList.LikeState = true;
        });
    },
    deldz() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "3";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.dzyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    addsc() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "2";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.scshow = true;
          this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    delsc() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "2";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.scshow = false;
          this.scyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },

    addyy() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "5";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/Handle", this.watched)
        .then((res) => {
          this.yyshow = true;
          this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    delyy() {
      this.watched.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.watched.CustomerId = this.userInfo.Id;
      this.watched.type = "5";
      let that = this;
      that.$axios
        .post("/Api/Api/Web/Article/DelHandle", this.watched)
        .then((res) => {
          this.yyshow = false;
          this.yyyes = false;
          // this.dzzt();
          // this.list.CommentList.LikeState = true;
        });
    },
    enteryy() {
      let that = this;
      that.$axios
        .get("/Api/Api/Web/Live/LiveDocking?artId=" + this.list.Id)
        .then((res) => {
          window.location.href = res.Data;
        });
    },

    addArticleStars() {
      let that = this;
      this.getstar.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.getstar.CustomerId = this.userInfo.Id;
      if (that.rate1 == 1) {
        that.uprate1 = 20;
      } else if (that.rate1 == 2) {
        that.uprate1 = 40;
      } else if (that.rate1 == 3) {
        that.uprate1 = 60;
      } else if (that.rate1 == 4) {
        that.uprate1 = 80;
      } else if (that.rate1 == 5) {
        that.uprate1 = 100;
      }
      that.getstar.ProfessionalDegree = that.uprate1;
      if (that.rate2 == 1) {
        that.uprate2 = 20;
      } else if (that.rate2 == 2) {
        that.uprate2 = 40;
      } else if (that.rate2 == 3) {
        that.uprate2 = 60;
      } else if (that.rate2 == 4) {
        that.uprate2 = 80;
      } else if (that.rate2 == 5) {
        that.uprate2 = 100;
      }
      that.getstar.Practicability = that.uprate2;
      if (that.rate3 == 1) {
        that.uprate3 = 20;
      } else if (that.rate3 == 2) {
        that.uprate3 = 40;
      } else if (that.rate3 == 3) {
        that.uprate3 = 60;
      } else if (that.rate3 == 4) {
        that.uprate3 = 80;
      } else if (that.rate3 == 5) {
        that.uprate3 = 100;
      }
      that.getstar.RecommendationDegree = that.uprate3;
      that.$axios
        .post("/Api/Api/Web/Article/AddArticleStars", that.getstar)
        .then((res) => {
          Dialog.alert({
            message: "提交成功！",
          }).then(() => {
            this.getArticleStars();
          });
        });
    },

    editArticleStars() {
      let that = this;
      this.getstar.ArticleId =
        typeof this.$route.query.id !== "undefined" ? this.$route.query.id : 0;
      this.getstar.CustomerId = this.userInfo.Id;
      if (that.rate1 == 1) {
        that.uprate1 = 20;
      } else if (that.rate1 == 2) {
        that.uprate1 = 40;
      } else if (that.rate1 == 3) {
        that.uprate1 = 60;
      } else if (that.rate1 == 4) {
        that.uprate1 = 80;
      } else if (that.rate1 == 5) {
        that.uprate1 = 100;
      }
      that.getstar.ProfessionalDegree = that.uprate1;
      if (that.rate2 == 1) {
        that.uprate2 = 20;
      } else if (that.rate2 == 2) {
        that.uprate2 = 40;
      } else if (that.rate2 == 3) {
        that.uprate2 = 60;
      } else if (that.rate2 == 4) {
        that.uprate2 = 80;
      } else if (that.rate2 == 5) {
        that.uprate2 = 100;
      }
      that.getstar.Practicability = that.uprate2;
      if (that.rate3 == 1) {
        that.uprate3 = 20;
      } else if (that.rate3 == 2) {
        that.uprate3 = 40;
      } else if (that.rate3 == 3) {
        that.uprate3 = 60;
      } else if (that.rate3 == 4) {
        that.uprate3 = 80;
      } else if (that.rate3 == 5) {
        that.uprate3 = 100;
      }
      that.getstar.RecommendationDegree = that.uprate3;
      that.getstar.id = that.stars.Id;
      that.$axios
        .post("/Api/Api/Web/Article/UpdateArticleStars", that.getstar)
        .then((res) => {
          Dialog.alert({
            message: "修改成功！",
          }).then(() => {
            this.getArticleStars();
          });
        });
    },

    jump(Id, Type) {
      this.$router.push({
        path: "contentPage",
        query: {
          id: Id,
          type: Type,
        },
      });
      location.reload();
    },

    getArticleStars() {
      let that = this;
      that.rate1 = 0;
      that.rate2 = 0;
      that.rate3 = 0;
      that.$axios
        .post("/Api/Api/Web/Article/GetArticleStars", this.dzt)
        .then((res) => {
          that.rate1 = 0;
          that.rate2 = 0;
          that.rate3 = 0;
          that.stars = res.Data;
          if (that.stars.ProfessionalDegree == 20) {
            that.rate1 = 1;
          } else if (that.stars.ProfessionalDegree == 40) {
            that.rate1 = 2;
          } else if (that.stars.ProfessionalDegree == 60) {
            that.rate1 = 3;
          } else if (that.stars.ProfessionalDegree == 80) {
            that.rate1 = 4;
          } else if (that.stars.ProfessionalDegree == 100) {
            that.rate1 = 5;
          } else if (
            that.stars.ProfessionalDegree == 0 ||
            that.stars.ProfessionalDegree == null ||
            that.stars.ProfessionalDegree == undefined
          ) {
            that.rate1 = 0;
          }
          if (that.stars.Practicability == 20) {
            that.rate2 = 1;
          } else if (that.stars.Practicability == 40) {
            that.rate2 = 2;
          } else if (that.stars.Practicability == 60) {
            that.rate2 = 3;
          } else if (that.stars.Practicability == 80) {
            that.rate2 = 4;
          } else if (that.stars.Practicability == 100) {
            that.rate2 = 5;
          } else if (
            that.stars.Practicability == 0 ||
            that.stars.Practicability == null ||
            that.stars.Practicability == undefined
          ) {
            that.rate2 = 0;
          }
          if (that.stars.RecommendationDegree == 20) {
            that.rate3 = 1;
          } else if (that.stars.RecommendationDegree == 40) {
            that.rate3 = 2;
          } else if (that.stars.RecommendationDegree == 60) {
            that.rate3 = 3;
          } else if (that.stars.RecommendationDegree == 80) {
            that.rate3 = 4;
          } else if (that.stars.RecommendationDegree == 100) {
            that.rate3 = 5;
          } else if (
            that.stars.RecommendationDegree == 0 ||
            that.stars.RecommendationDegree == null ||
            that.stars.RecommendationDegree == undefined
          ) {
            that.rate3 = 0;
          }
          that.ratebtnshow = 1;
        });
    },
    filedownload(FilesUrl) {
      location.href = FilesUrl;
    },
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 8em;
  position: relative;
}

.title {
  width: 90%;
  margin: 0.5em auto;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  padding-top: 0.5em;
}

.item_label {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 90%;
  span {
    display: inline-block;
    height: 20px;
    line-height: 20px;
    border-radius: 5px;
    padding: 0.3em 1em;
    margin-right: 6px;
    margin-bottom: 5px;
    background-color: #f14d4d21;
    font-size: 12px;
    color: red;
  }
}

.timetab {
  position: relative;
  margin: 1em auto;
}

.time {
  width: 90%;
  margin: 0.5em auto;
  font-size: 12px;
  color: #adadad;
}

.fontSize {
  display: block;
  position: absolute;
  right: 2%;
  top: 50%;
  font-size: 0.9em;
  transform: translatey(-50%);
  color: #8b8b8b;
  vertical-align: middle;
  img {
    width: 4em;
    vertical-align: middle;
  }
}

.sicon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1em;
  font-size: 12px;
  img {
    width: 1.5em;
    // vertical-align: baseline;
  }
}

.content1 {
  width: 90%;
  margin: auto;
  margin-top: 1.5em;
}

.content2 {
  width: 90%;
  margin: auto;
  margin-top: 1.5em;
}

.content3 {
  margin-top: 1em;
}

.detail {
  margin: auto;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
  img {
    margin: 0.8em 0;
  }
}

/deep/ .Desc > p > img {
  width: 100%;
}

/deep/ .VContent > p > img {
  width: 100%;
}

.detailzb {
  margin: auto;
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd;
  img {
    margin: 0.8em 0;
  }
}

.article {
  width: 90%;
  margin: 1em auto;
}

.articletitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.3em;
}

.articleinfo {
  color: #6e6e6e;
  font-size: 14px;
}

/deep/ section {
  width: 100%;
  img {
    width: 100% !important;
    height: auto !important;
  }
  p {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.banner {
  /deep/ p {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

/deep/ .articleinfo > p > img {
  width: 100%;
}

.dzmain {
  background: #f9f9f9;
  width: 83%;
  margin: auto;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.8em;
}

.dztitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
}

.ratename {
  display: inline-block;
  margin-right: 2em;
  vertical-align: middle;
  font-size: 15px;
  color: #606060;
}

.raterow {
  text-align: center;
  margin-bottom: 0.8em;
}

.van-rate {
  vertical-align: middle;
}

.rateupload {
  border: 0;
  width: 67%;
  text-align: center;
  display: block;
  margin: 1em auto !important;
  margin-top: 1.5em !important;
  border-radius: 0.5em;
  background: #d11919;
  font-size: 14px;
}

.rowbtn {
  width: 90%;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 1em;
}

.plbtn {
  color: red;
  border-color: red;
  border-radius: 6px;
  width: 38%;
  font-size: 14px;
}

.plmain {
  width: 90%;
  margin: auto;
  margin-bottom: 2em;
}

.plrow {
  width: 91%;
  background: #f9f9f9;
  padding: 1em;
  border-radius: 10px;
  padding-bottom: 2.5em;
  position: relative;
  margin-bottom: 1em;
}

.tx {
  display: inline-block;
  width: 2.5em;
  border-radius: 5em;
  overflow: hidden;
  vertical-align: middle;
  img {
    width: 100%;
    display: block;
  }
}

.plname {
  display: inline-block;
  font-size: 14px;
  margin-left: 0.5em;
}

.pltop {
  position: relative;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5em;
}

.pltime {
  position: absolute;
  right: 0;
  font-size: 12px;
  top: 22%;
  //   transform: translateY(-50%);
  color: #ababab;
}

.pltext {
  margin: 1em 0;
  font-size: 14px;
}

.pldz {
  position: absolute;
  right: 0.8em;
  bottom: 0.8em;
  img {
    width: 1.4em;
    vertical-align: bottom;
  }
  span {
    vertical-align: middle;
    margin-left: 0.4em;
    color: #ababab;
    font-size: 14px;
  }
}

.aboutmain {
  width: 90%;
  margin: auto;
}

.abouttop {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.aboutrow {
  background: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1em;
  padding: 0.5em 0;
}

.aboutimg {
  width: 45%;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  .imgmain {
    width: 100%;
    display: block;
  }
}

.aboutmaininfo {
  display: inline-block;
  width: 49%;
  margin-left: 2%;
  vertical-align: middle;
}

.abouttitle {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
  min-height: 2.7em;
  font-size: 14px;
}

.aboutname {
  font-size: 12px;
}

.abouthos {
  font-size: 12px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.ssicon {
  font-size: 11px !important;
  vertical-align: middle;
  img {
    vertical-align: baseline !important;
  }
}

.playicon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2em;
  height: 2em;
  transform: translate(-50%, -50%);
}

.bottombar {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  text-align: center;
  padding-bottom: 1.5em;
  padding-top: 0.5em;
}

.toTop {
  position: fixed;
  right: 10px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-bottom: 1.5em;
  padding-top: 0.5em;
  z-index: 99999;
  a {
    scroll-behavior: smooth;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bottomrow {
  display: inline-block;
  width: 33%;
}

.bottomimg {
  width: 1.5em;
}

.bottomtext {
  font-weight: bold;
  font-size: 0.9em;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  // padding-bottom: 1.5em;
  // padding-top: 1em;
  text-align: center;
  // background-color: #f9f9f9;
  border-radius: 10px;
  img {
    // width: 90% !important;
    width: 100%;
  }
}

.custom-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.3rem;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5em 1.5em;
  border-radius: 5em;
}

.want {
  border: 0;
  width: 80%;
  text-align: center;
  display: block;
  margin: 1em auto !important;
  margin-bottom: 1em !important;
  border-radius: 0.5em;
  background: #d11919;
  font-size: 1.1em;
}

.detailzb {
  width: 90%;
  // background: #f9f9f9;
  border-radius: 10px;
  border: 0;
  img {
    border-radius: 10px;
  }
}

.bannertop {
  position: absolute;
  top: 3%;
  left: 2.5%;
  font-weight: bold;
}

.filedownload {
  width: 77%;
  margin: 1em auto;
  background: #fbf4f4;
  border: 1px solid #ffadad;
  border-radius: 5px;
  position: relative;
  padding: 0.5em 1.5em;
  padding-top: 0.8em;
  padding-bottom: 2em;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 2em;
  }
}

.fileTitle {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 14px;
}

.filebottom {
  position: absolute;
  right: 1em;
  font-size: 11px;
  color: red;
  bottom: 0.5em;
}

.van-popup {
  padding-top: 3em;
}

.plinput {
  margin: auto;
  margin-bottom: 2em;
  width: 90%;
  border: 1px solid red;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 1em;
}

.frame {
  width: 100%;
  height: 13em;
}

.shlog {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  .shin {
    position: fixed;
    width: 100%;
    top: 20px;
    left: 0;
    .shlog1 {
      display: block;
      width: 16%;
      margin: 0 auto;
      margin-top: 0.3rem;
      margin-right: 9%;
    }
    .shlog2 {
      width: 61%;
      display: block;
      margin: 0 auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .shlog3 {
      color: #fff;
      font-size: 16px;
      width: 70%;
      margin: 0 auto;
    }
    .sslog {
      width: 22px;
      margin-right: 5px;
      vertical-align: middle;
    }
    .sslog1 {
      vertical-align: middle;
      width: 22px;
      margin: 0 5px;
    }
    span {
      vertical-align: middle;
    }
    .shlog4 {
      color: #fff;
      font-size: 16px;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      width: 70%;
    }
    .known {
      width: 56%;
      text-align: center;
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.1);
      border: 2px dashed #fff;
      border-radius: 5px;
      padding: 10px;
      margin-top: 1rem;
    }
  }
}
</style>